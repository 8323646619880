import React, { useEffect, useState } from 'react';
import { listAllFiles, downloadFile, deleteFile, getDocsByQuery } from '../firebase';
import './ListNewOrders.css';
import Popup from './Popup';
import OrderNewDetails from './OrderNewDetails';
import JSZip from 'jszip';
import { saveAs } from 'file-saver';
import { useSearchParams } from 'react-router-dom';

async function ordersIDandNumber() {
  const orders = await getDocsByQuery("orders", "status", "final-paid");
  const orders_array = [];
  if (orders.length > 0) {
      console.log("Documents matching the query:", orders);
      orders.forEach(order => {
          console.log("Order ID:", order.id);
          console.log("Order number:", order.data.order_number);
          const toPrint = order.data.toPrint;
          console.log("toPrint:", toPrint);
          
          const items = [];
          toPrint.forEach(print => {
              const printData = print.data;
              items.push({
                  sku: printData.sku,
                  name: printData.current_frame_name,
                  original: printData.image_src,
                  cropped: printData.cropped_image_url
              });
          });
          orders_array.push({
              order_id: order.id, 
              order_number: order.data.order_number,
              items: items
          });
      });
  } else {
      console.log("No documents found matching the query.");
  }
  return orders_array;
}

const ListNewOrders = () => {
    const [files, setFiles] = useState([]);
    const [topLevelOrders, setTopLevelOrders] = useState([]);
    const [error, setError] = useState(null);
    const [showOrderNewDetails, setShowOrderNewDetails] = useState(false);
    const [popupContent, setPopupContent] = useState(null);
    const [searchParams] = useSearchParams();
    const [deleteConfirmation, setDeleteConfirmation] = useState(null);
    
    const toggleOrderNewDetails = (order) => {
        setPopupContent(order);
        setShowOrderNewDetails(!showOrderNewDetails);
    };

    const downloadAllCroppedImages = async (order) => {
        const zip = new JSZip();
        
        order.items.forEach((item, index) => {
          zip.file(`${order.order_number}_${item.current_frame_name}_item_${index}_cropped.jpg`, fetch(item.cropped).then(res => res.blob()));
        });

        const zipFile = await zip.generateAsync({ type: 'blob' });
        saveAs(zipFile, `${order.order_number}_cropped_images.zip`);
    };

    const handleDeleteClick = (file) => {
        setDeleteConfirmation(file);
    };

    const handleDeleteConfirm = async () => {
        try {
            const filename = deleteConfirmation.name.split('/').pop();  
            
            await deleteFile('orders', filename);
            setFiles(files.filter(f => f.name !== deleteConfirmation.name));
            setDeleteConfirmation(null);
        } catch (error) {
            setError(error);
        }
    };

  useEffect(() => {
    const fetchFiles = async () => {
      try {
        setTopLevelOrders(await ordersIDandNumber());
        // console.log(await ordersIDandNumber());
        // console.log('topLevelOrders', topLevelOrders);
      } catch (err) {
        setError(err);
      }
    };

    fetchFiles();
  }, [searchParams]);

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  return (
    <div className="orders-list w-full h-full">
      <div className="relative h-full">
        <table className="min-w-full bg-white">
          <thead className="sticky top-0 bg-white z-10">
            <tr>
              <th className="py-2 px-4 bg-gray-200">Order Number</th>
              <th className="py-2 px-4 bg-gray-200">Order ID</th>
              <th className="py-2 px-4 bg-gray-200">Actions</th>
            </tr>
          </thead>
          <tbody className="overflow-y-auto">
          {topLevelOrders.map((order, index) => (
              <tr key={index} className="border-b">
                <td className="py-2 px-4">{order.order_number}</td>
                <td className="py-2 px-4">{order.order_id}</td>
                <td className="py-2 px-4">
                  <button
                      onClick={() => toggleOrderNewDetails(order)}
                      className="bg-green-500 text-white py-1 px-3 rounded hover:bg-green-700 transition duration-200 mr-2"
                  >
                      Order Details
                  </button>
                  <button
                      onClick={() => downloadAllCroppedImages(order)}
                      className="bg-blue-500 text-white py-1 px-3 rounded hover:bg-blue-700 transition duration-200"
                  >
                      Download All Cropped
                  </button>
                  <button
                      // onClick={() => handleDeleteClick(order)}
                      className="bg-red-500 text-white py-1 px-3 rounded hover:bg-red-700 transition duration-200"
                  >
                      Delete
                  </button>
                  {showOrderNewDetails && (
                    <Popup
                      triggerText="Order Details"
                      isOpen={showOrderNewDetails}
                      togglePopup={() => toggleOrderNewDetails(null)}
                    >
                      <OrderNewDetails order={popupContent} />
                    </Popup>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {deleteConfirmation && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white p-6 rounded-lg shadow-lg max-w-sm w-full">
            <h3 className="text-lg font-semibold mb-4">Confirm Delete</h3>
            <p className="mb-4">
              Are you sure you want to delete order {deleteConfirmation.content.oid}? 
              This action cannot be undone.
            </p>
            <div className="flex justify-end gap-4">
              <button
                onClick={() => setDeleteConfirmation(null)}
                className="bg-gray-500 text-white py-2 px-4 rounded hover:bg-gray-700 transition duration-200"
              >
                Cancel
              </button>
              <button
                onClick={handleDeleteConfirm}
                className="bg-red-500 text-white py-2 px-4 rounded hover:bg-red-700 transition duration-200"
              >
                Delete
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ListNewOrders;