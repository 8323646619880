import { initializeApp } from "firebase/app";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { getStorage, ref, uploadBytes, getDownloadURL, listAll, deleteObject, getMetadata } from "firebase/storage";
import { getFirestore, doc, setDoc, collection, getDocs, deleteDoc, query, where, } from "firebase/firestore";
import { getCollectionName } from './utils/collections';

const firebaseConfig = {
    apiKey: "AIzaSyBw5sgOF4eO8B-K3eMnPlIu9r6c2Xw2lSg",
    authDomain: "moments-in-light-global.firebaseapp.com",
    projectId: "moments-in-light-global",
    storageBucket: "moments-in-light-global.appspot.com",
    messagingSenderId: "133852955764",
    appId: "1:133852955764:web:e581bf13b20e614a317d21",
    measurementId: "G-7M9GHYWDPL"
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const storage = getStorage(app);
const firestore = getFirestore(app);

// Function to get storage path based on site
const getStoragePath = (path) => {
    const urlParams = new URLSearchParams(window.location.search);
    const site = urlParams.get('site');
    return site === 'il' ? `${path}-il` : path;
};

export async function getDocsByQuery(collectionPath, fieldName, fieldValue) {
    try {
        const collectionRef = collection(firestore, collectionPath);
        const q = query(collectionRef, where(fieldName, "==", fieldValue));

        const querySnapshot = await getDocs(q);
        const results = [];

        querySnapshot.forEach((doc) => {
            const data = doc.data();
            results.push({ id: doc.id,  data: data }); // Only include the queried field
        });

        return results;
    } catch (error) {
        console.error("Error getting documents:", error);
        return [];
    }
}

// async function example() {
//     const orders = await getDocsByQuery("orders", "status", "final-paid");
    
//     if (orders.length > 0) {
//         console.log("Documents matching the query:", orders);
//         orders.forEach(order => {
//             console.log("Order ID:", order.id);
//             console.log("Order status:", order.data.status);
//             console.log("Order number:", order.data.order_number);
//             const toPrint = order.data.toPrint;
//             toPrint.forEach(print => {
//                 const printData = print.data;
//                 console.log("Print sku:", printData.sku);
//                 console.log("Print name:", printData.current_frame_name);
//                 console.log("Print cropped:", printData.cropped_image_url);
//                 console.log("Print original:", printData.image_src);
//             });
//         });
//     } else {
//         console.log("No documents found matching the query.");
//     }
// }

// example();

// Function to list all files from a specified path in Firebase Storage
export const listAllFiles = async (path) => {
    const modifiedPath = getStoragePath(path);
    const storageRef = ref(storage, modifiedPath);
    try {
        const res = await listAll(storageRef);
        const files = res.items.map((itemRef) => itemRef.fullPath);
        return files;
    } catch (error) {
        console.error("Error listing files: ", error);
        throw error;
    }
};

// Function to upload a file to Firebase Storage
export const uploadFile = async (path, file, name) => {
    const modifiedPath = getStoragePath(path);
    const storageRef = ref(storage, `${modifiedPath}/${name}`);
    await uploadBytes(storageRef, file);
    const downloadURL = await getDownloadURL(storageRef);
    return downloadURL;
};

// Function to delete a file from Firebase Storage
export const deleteFile = async (path, filename) => {
    const modifiedPath = getStoragePath(path);
    const fileRef = ref(storage, `${modifiedPath}/${filename}`);
    try {
        await deleteObject(fileRef);
        return true;
    } catch (error) {
        console.error("Error deleting file:", error);
        throw error;
    }
};

// Function to download a file from Firebase Storage
export const downloadFile = async (path, name) => {
    const modifiedPath = getStoragePath(path);
    const storageRef = ref(storage, `${modifiedPath}/${name}`);
    const downloadURL = await getDownloadURL(storageRef);
    return downloadURL;
};

// Function to write an object to Firestore Database
export const writeObjectToFirestore = async (path, name, object) => {
    const collectionName = getCollectionName(path);
    const docRef = doc(firestore, collectionName, name);
    await setDoc(docRef, object);
};

// Function to edit an object in Firestore Database
export const editObjectInFirestore = async (path, name, object) => {
    const collectionName = getCollectionName(path);
    const docRef = doc(firestore, collectionName, name);
    await setDoc(docRef, object, { merge: true });
};

// Function to delete an object from Firestore Database
export const deleteObjectFromFirestore = async (path, name) => {
    try {
        const collectionName = getCollectionName(path);
        const docRef = doc(firestore, collectionName, name);
        await deleteDoc(docRef);
        console.log('Document deleted successfully');
    } catch (error) {
        console.error('Error deleting document:', error);
    }
};

// Function to read an entire collection from Firestore
export const readCollectionFromFirestore = async (collectionName) => {
    try {
        const modifiedCollectionName = getCollectionName(collectionName);
        const collectionRef = collection(firestore, modifiedCollectionName);
        const querySnapshot = await getDocs(collectionRef);
        const documents = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        return documents;
    } catch (error) {
        console.error('Error reading collection: ', error);
        return [];
    }
};

// Function to observe authentication state
export const observeAuthState = (callback) => {
    onAuthStateChanged(auth, (user) => {
        if (user) {
            // User is signed in
            callback(user);
        } else {
            // User is signed out
            callback(null);
        }
    });
};

// Function to get metadata for a file from Firebase Storage
export const getFileMetadata = async (path, filename) => {
    const modifiedPath = getStoragePath(path);
    const fileRef = ref(storage, `${modifiedPath}/${filename}`);
    try {
        const metadata = await getMetadata(fileRef);
        return metadata;
    } catch (error) {
        console.error("Error getting file metadata: ", error);
        throw error;
    }
};

export { auth };